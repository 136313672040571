<template>
  <router-link
    :to="{ name: 'salon-services' }"
    class=" block mb-3 text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900"
  >
    <i class="mr-2 pi pi-arrow-left"></i>
    Kembali
  </router-link>

  <div class="mb-5 card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Detail Layanan Salon</h1>
    <div class="grid mb-5">
      <div class="col-12 md:col-4">
        <FormKit
          v-model="data"
          :type="uploadImage"
          name="image"
          label="Foto"
          validation="required"
          validation-visibility="dirty"
          :classes="defaultFormikClass"
          :src="detail.image ? detail.image : require('@/assets/images/dummy.jpg')"
          :upload="updateServiceImage"
        />
      </div>
      <div class="col-0 md:col-1"></div>
      <div class="col-12 md:col-7">
        <h5 class="tw-text-lg tw-font-semibold">Detail</h5>
        <hr class="mt-2 mb-4" />
        <div class="grid mb-2" v-for="column in columns" :key="`_${column.field}`">
          <div class="col-6 md:col-4 opacity-60">
            {{ column.header }}
          </div>
          <div class="col-6 md:col-8 tw-font-medium">
            <div v-if="!column?.process">
              {{ detail[column.field] }}
            </div>
            <div v-else>{{ column.process(detail[column.field]) }}</div>
          </div>
        </div>
        <div class="grid">
          <div class="col-6 md:col-4 opacity-60">Active</div>
          <div class="col-6 md:col-8">
            <InputSwitch
              v-model="detail.isActive"
              @change="activeInactiveService(detail)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";

import { defaultFormikClass } from "@/constants/formik";
import {
  useSalonServiceDetail,
  useSalonServiceForm,
} from "@/composables/salon_service";
import { useRoute } from "vue-router";
import { createInput } from "@formkit/vue";
import FormUploadImage from "@/components/form/FormUploadImage";
import { useToast } from "primevue/usetoast";

const toast = useToast()
const route = useRoute();
const uploadImage = createInput(FormUploadImage, {
  props: ["src", "upload"],
});

const { detail, getDetail } = useSalonServiceDetail();
const { updateImage, update } = useSalonServiceForm();
const data = ref(null);

const columns = [
  { field: "name", header: "Nama Layanan" },
  { field: "service_desc", header: "Deskripsi Layanan" },
  { field: "salon_name", header: "Nama Salon" },
  { field: "category_name", header: "Kategori Layanan" },
  {
    field: "estimated_time",
    header: "Estimasi Waktu",
    process: (data) => `${data} Minutes`,
  },
];

const updateServiceImage = async (data) => {
  await updateImage(route.params.id, data);
  await getDetail(route.params.id);
};

const activeInactiveService = async (data) => {
  data.service_name = data.name
  data.category_id = parseInt(data.category_id)
  data.salon_id = parseInt(data.salon_id)
  data.is_home_service = parseInt(data.is_home_service)
  data.estimated_time = parseInt(data.estimated_time)
  data.admin_fee = parseInt(data.admin_fee)
  data.status = data.isActive ? 1 : 0

  let response = await update(route.params.id, data);

  if (response.code === 200) {
    toast.add({
      severity: 'success',
      summary: 'Success',
      detail: response.message,
      life: 3000,
    });
  } else {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: response.message,
      life: 3000,
    });
  }
}

onBeforeMount(async () => {
  await getDetail(route.params.id);
});
</script>